export const CSS_CLASSES = {
  ROOT: 'Button',
  ICON: 'Button__Icon',
  LABEL: 'Button__Label',
  DENSE: 'Button_dense',
  RAISED: 'Button_raised',
  OUTLINED: 'Button_outlined',
  UNELEVATED: 'Button_unelevated',
  UPPERCASE: 'Button_uppercase'
};
